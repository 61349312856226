import { pushWithRegion } from 'actions/NavigationActions'
import Modal from 'components/Luxkit/Modal/Modal'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { authLogout } from 'actions/AuthActions'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import config from 'constants/config'

interface MappedStateProps {
  account: App.AuthAccount,
  currentBusinessAccount?: App.BusinessTraveller.CurrentBusinessAccount
}

function BusinessTravellerAccountErrorModal(props: MappedStateProps) {
  const { account, currentBusinessAccount } = props
  const dispatch = useAppDispatch()

  const modalState = useModalElementContext<'item-clicked'>()

  const handleItemClick = useCallback(() => {
    modalState?.resolve('item-clicked')
  }, [modalState])

  const logOut = useCallback(() => {
    // stolen from `AccountLandingPage.tsx`
    config.GOOGLE_ONE_TAP_LOGIN && window.google?.accounts?.id.disableAutoSelect()
    dispatch(authLogout())
    handleItemClick()
    dispatch(pushWithRegion('/'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logOutAndDirectToBusinessSignup = useCallback(() => {
    // stolen from `AccountLandingPage.tsx`
    config.GOOGLE_ONE_TAP_LOGIN && window.google?.accounts?.id.disableAutoSelect()
    dispatch(authLogout())
    handleItemClick()
    dispatch(pushWithRegion('/business-traveller/business-signup'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      dismissible={false}
      isOpen={currentBusinessAccount?.status === 'failed'}
      title="Your account is not associated with a business"
      size="S"
      primaryActionText="Log out"
      onPrimaryActionClick={logOut}
      height="full"
      tabletHeight="auto"
      secondaryActionText="Join now for free"
      onSecondaryActionClick={logOutAndDirectToBusinessSignup}
    >
      <Group direction="vertical" gap={12}>
        <BodyText variant="large">Hey {account.givenName}, it looks like you've signed in with your Luxury Escapes account.</BodyText>

        <BodyText variant="large">To access the perks of Business Traveller, reach out to your company's travel manager or accept your pending email invitation.</BodyText>

        <BodyText variant="large">If you would like to register your business, simply join now for free.</BodyText>
      </Group>
    </Modal>
  )
}

export default connect<MappedStateProps, undefined, {}, App.State>((state) => {
  return {
    currentBusinessAccount: state.businessTraveller.currentBusinessAccount,
    account: state.auth.account,
  }
})(BusinessTravellerAccountErrorModal)
